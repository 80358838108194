<template>
  <div class="loan-application h-100">
    <Container class="h-100">
      <b-row>
        <b-col md="10" offset-md="1">
          <Card
            padding="large"
            variant="border"
            overflow="hidden"
            class="h-100"
          >
            <Margins>
              <h1 class="h3 text-normal">{{ $t('LOAN_APPLICATION') }}</h1>

              <LoanApplicationLogin v-if="showLogin" />
              <RenewalApplicationForm
                v-else-if="isRenewableOrder()"
                :is-renewable="isRenewableOrder()"
                :reference="getReference()"
              />
              <LoanApplicationForm v-else class="margins__triple" />
            </Margins>
          </Card>
        </b-col>
      </b-row>
    </Container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Card, Container, Margins } from '@/components';
import {
  LoanApplicationForm,
  LoanApplicationLogin,
  RenewalApplicationForm,
} from '@/containers';
import { constants } from '@/mixins';

export default {
  name: 'LoanApplication',
  mixins: [constants],
  components: {
    RenewalApplicationForm,
    Container,
    Card,
    Margins,
    LoanApplicationLogin,
    LoanApplicationForm,
  },
  data() {
    return {
      isRenewable: false,
      reference: String,
    };
  },
  methods: {
    isRenewableOrder() {
      this.isRenewable = localStorage.getItem('isRenewable');
      return this.isRenewable === 'true';
    },
    getReference() {
      this.reference = localStorage.getItem('reference');
      return this.reference;
    },
  },
  computed: {
    ...mapState(['loginInfo', 'isPhysicalStore']),
    showLogin() {
      return this.FEATURE_LOGIN && !this.isPhysicalStore && !this.loginInfo;
    },
  },
};
</script>
